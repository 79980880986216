import m from "mithril";

import dayjs from "dayjs";
import { LandingTemplates } from "./landing-templates";
import {
  Testimonial,
  TestimonialsCommunity,
  TestimonialsEditor,
  TestimonialsTemplates,
} from "./landing-testimonials";
import { accountState } from "../shared/account";
import { canonicalOrigin } from "../shared/config";
import { Icon20 } from "../shared/icon";
import { logEvent } from "../shared/log-event";
import { ModalX, modalState } from "../shared/modal";
import { SiteLayout } from "../shared/site-layout/site-layout";
import { CuttlePro, PricingFeature, UpgradeInfo } from "../shared/upgrade";
import { classNames, goToDashboard } from "../shared/util";
import { markdownToHtml, updateHead } from "../util";
import { LandingPageHero } from "./landing-hero";

export const LaserCraftFestLandingPage: m.Component = {
  view() {
    updateHead({
      title: "Cuttle: Generate Personalized SVG Cut Files in seconds",
      description:
        "Cuttle is a web-based design tool for laser cutting. Make personalized ornaments, cake toppers, keychains, boxes, jewelry, connected text, and more.",
      image: canonicalOrigin + "/images/social.jpg",
    });

    const cta = m(ClaimFreeMonthButton);

    return m(SiteLayout, { className: "laser-craft-fest-landing-page" }, [
      m(".landing-page-content", [
        //
        m(LandingPageHero, { cta }),

        m(".section-tagline", [m("h3", "Templates")]),
        m(LandingTemplates),
        m(ClaimFreeMonthButton),

        m(".section-tagline", [
          m("h3", "Community"),
          m("h1", "Join an amazing community of ", m("span", "artists, engineers, and makers")),
        ]),
        m(TestimonialsTemplates),
        m(TestimonialsEditor),
        m(TestimonialsCommunity),
        m(ClaimFreeMonthButton),

        m(OurWorkshopsAtLCF2024),
        m(OurWorkshopsAtLCF2023),
      ]),
    ]);
  },
};

const workshopBoxes = `
## Making Boxes of All Shapes and Sizes

Federico teaches you everything you need to know to make laser cut boxes with finger joints!

Get started with the free [Open Box template](https://cuttle.xyz/@cuttle/Open-Box-with-Finger-Joints-D2ugGEvYUNfd).

Supplies shown in the workshop (contains affiliate links)

- [Inexpensive digital calipers](https://amzn.to/3wGxcCG) for measuring your material thickness
- [Glowforge Medium Draftboard](https://shop.glowforge.com/products/draftboard), or any plywood or MDF
- [Wood glue](https://amzn.to/3reMrDp) (optional)
- [Wood glue dispenser](https://amzn.to/415K1mC) (optional)

Federico also shows other box templates like the [Hinged Lid Box](https://cuttle.xyz/@cuttle/Hinged-Lid-Box-rTDUUz652dVO) and [Heart Shaped Box](https://cuttle.xyz/@cuttle/Heart-Shaped-Box-7yx9inQPEQHY) at the end of the video.
`;
const workshopQuickly = `
## Keychains, Connected Text, & Cake Toppers — Creating Custom Cut Files Quickly!

Federico shows you some projects you can personalize in seconds with your own custom text!

Featured in this workshop:

- [Keychain Generator](https://cuttle.xyz/@cuttle/Keychain-Generator-j0lMJaBnDHn0)
- [Connected Text](https://cuttle.xyz/@cuttle/Connected-Text-29M9IXUSr5yr)
- [Cake Topper Generator](https://cuttle.xyz/@cuttle/Cake-Topper-Generator-Lq1sMetUmSul)
- [Name Puzzle](https://cuttle.xyz/@cuttle/Name-Puzzle-cuhgyU5lJ7Mo)
- [Name Snowflake Ornament](https://cuttle.xyz/@cuttle/Name-Snowflake-Ornament-5HKNEddwMGMR)

Here are the supplies Federico used in the video:

- [Proofgrade Light Maple Plywood](https://shop.glowforge.com/collections/plywood/products/maple-plywood-finished?variant=39451629191266)
- [Two Tone Blue/White Acrylic](https://houstonacrylic.com/products/two-tone-acrylic-blue-white-sheet) - Cut with Proofgrade Medium Black Acrylic (150/full). Engrave with 1000 Speed, 80 Power, 225 LPI.
- [Gunmetal Gray Mirror Acrylic](https://houstonacrylic.com/products/1-8-black-gunmetal-mirror-acrylic-sheet) - Cut with Proofgrade Medium Black Acrylic (150/full).
- [Purple Starry Sky Acrylic](https://houstonacrylic.com/products/purple-stary-sky-acrylic) - Cut with Proofgrade Medium Black Acrylic (150/full).
- [Nebula Confetti Acrylic](https://houstonacrylic.com/products/1-8-tbd-royal-chunky-hex-confetti-cast-acrylic-sheet) - Cut with Proofgrade Medium Black Acrylic (150/full).
`;
const workshopCuteAnimals = `
## Intro to the Cuttle Editor: Designing Cute Animals

Have you ever wanted to design your own SVG cut files?

Federico shows how to make a cute animal from scratch in the Cuttle editor, inspired by Ed Emberley’s classic Drawing Book of Animals.

This workshop is a bonus for those who purchased the All Access Crafters Pass.

At the end of the class you will have designed your own cute animal keychain and learned the fundamental skills to embark on your design journey!
`;

const workshopChristmasOrnaments = `
## Personalized Christmas Ornaments in Seconds

Type in text and choose options to instantly generate customized SVGs.

We show how to make stocking tags, paw ornaments, and family tree ornaments with the names of everyone in the family.

We also share marketing, pricing, and packaging tips from makers who sold hundreds of these ornaments last Christmas.

Featured in this workshop:

- [Stocking Tags](https://cuttle.xyz/@cuttle/Stocking-Tags-7pAoHPuvL0YT)
- [Paw Ornament](https://cuttle.xyz/@cuttle/Paw-Ornament-irWGANS1uLkF)
- [Family Names Tree Ornament](https://cuttle.xyz/@cuttle/Family-Names-Christmas-Tree-Ornament-3TGvZj8E49p7)
`;

const workshopInlayEarrings = `
## Make Laser Cut Inlay Earrings

We explain what is needed to get the right press-fit with inlays and show you how to use our inlay kerf tester. After that we show you how to use one of our pre-made earring templates including cutting and assembly.

Featured in this workshop:

- [Inlay Kerf Tester](https://cuttle.xyz/@cuttle/Press-Fit-Inlay-Kerf-Tester-fzKFDlQL8TJ1)
- [Rounded Teardrop Earrings](https://cuttle.xyz/@cuttle/Rounded-Teardrop-Inlay-Earrings-E8aplkd4gPhA)
- [Cat Face Earrings](https://cuttle.xyz/@cuttle/Cat-Face-Inlay-Earrings-BhwlGSh34Fr4)
- [Curved Wedge Earrings](https://cuttle.xyz/@cuttle/Curved-Wedge-Inlay-Earrings-gFXPZTXpMb6n)

Here are the supplies Federico used in the video:

- [Glowforge Medium Maple Plywood](https://shop.glowforge.com/products/maple-plywood-finished?variant=20442193559650)
- [Purple Starry Sky Acrylic](https://houstonacrylic.com/products/purple-starry-sky-acrylic?variant=44671863193826)
- [Earhooks and jumprings (aka "findings")](https://www.etsy.com/shop/MonarchPine) - use code CUTTLE10 for 10% off
`;

const workshopDesignInlayEarrings = `
## How to Design Laser Cut Inlay Earrings

We walk you through the process of designing your own inlay earrings using the Cuttle Editor with the helper features contained in the Inlay Kit.

Featured in this workshop:

- [Inlay Kit](https://cuttle.xyz/@cuttle/Inlay-Kit-xIr0u7pAVaMq)

Here are the supplies Federico used in the video:

- [Glowforge Medium Maple Plywood](https://shop.glowforge.com/products/maple-plywood-finished?variant=20442193559650)
- [Glowforge Medium Walnut Plywood](https://shop.glowforge.com/products/walnut-plywood-finished?variant=20442194739298)
- [Earhooks and jumprings (aka "findings")](https://www.etsy.com/shop/MonarchPine) - use code CUTTLE10 for 10% off
`;

const OurWorkshopsAtLCF2024: m.Component<{}> = {
  view() {
    return [
      m(".section-tagline", [m("h1", "Our Workshops at ", m("span", "Laser Craft Fest 2024"))]),
      m(".lcf-workshop-section", [
        m(".lcf-workshop-image", [
          m("img", { src: "/images/laser-craft-fest/lcf-christmas-ornaments.jpg" }),
        ]),
        m(".lcf-workshop-description", [m.trust(markdownToHtml(workshopChristmasOrnaments))]),
      ]),
      m(".lcf-workshop-section", [
        m(".lcf-workshop-image", [
          m("img", { src: "/images/laser-craft-fest/lcf-inlay-earrings.jpg" }),
        ]),
        m(".lcf-workshop-description", [m.trust(markdownToHtml(workshopInlayEarrings))]),
      ]),
      m(".lcf-workshop-section", [
        m(".lcf-workshop-image", [
          m("img", { src: "/images/laser-craft-fest/lcf-design-inlay-earrings.jpg" }),
        ]),
        m(".lcf-workshop-description", [m.trust(markdownToHtml(workshopDesignInlayEarrings))]),
      ]),
    ];
  },
};

const OurWorkshopsAtLCF2023: m.Component<{}> = {
  view() {
    return [
      m(".section-tagline", [
        // m("h3", "Templates"),
        m("h1", "Our Workshops at ", m("span", "Laser Craft Fest 2023")),
      ]),
      m(".lcf-workshop-section", [
        m(".lcf-workshop-image", [m("img", { src: "/images/laser-craft-fest/lcf-boxes.jpeg" })]),
        m(".lcf-workshop-description", [m.trust(markdownToHtml(workshopBoxes))]),
      ]),
      m(".lcf-workshop-section", [
        m(".lcf-workshop-image", [m("img", { src: "/images/laser-craft-fest/lcf-quickly.jpeg" })]),
        m(".lcf-workshop-description", [m.trust(markdownToHtml(workshopQuickly))]),
      ]),
      m(".lcf-workshop-section", [
        m(".lcf-workshop-image", [
          m("img", { src: "/images/laser-craft-fest/lcf-cute-animals.jpeg" }),
        ]),
        m(".lcf-workshop-description", [m.trust(markdownToHtml(workshopCuteAnimals))]),
      ]),
    ];
  },
};

const ClaimFreeMonthButton: m.Component<{ className?: string }> = {
  view({ attrs: { className } }) {
    // A secret way to turn on the trial for testing or for occasional
    // stragglers that we want to extend the trial to.
    let showTrialButton = new URLSearchParams(window.location.search).get("extend") === "1";

    // Uncomment this line to turn on Free Trial (that is, when the LCF offer is active).
    // showTrialButton = true;

    if (!showTrialButton) {
      // If we're not showing the trial, then instead we'll show a normal Get
      // Started button.
      return m(GetStartedButton, { className });
    }

    return m(
      "button.create-free-account-button",
      {
        className,
        onclick: async () => {
          logEvent("lcf032024 clicked claim your free month");
          if (!accountState.loggedInUser) {
            const success = await accountState.openAccountModalPromise("signup");
            if (!success) return;
          }
          logEvent("lcf032024 logged in");
          if (accountState.loggedInUser?.subscription?.plan === "Free") {
            modalState.open({ modalView: () => m(ClaimYourFreeMonthModalPage1) });
          } else {
            modalState.open({ modalView: () => m(AlreadyProModal) });
          }
        },
      },
      "Claim Your Free Month"
    );
  },
};

const GetStartedButton: m.Component<{ className?: string }> = {
  view({ attrs: { className } }) {
    return m(
      "button.create-free-account-button",
      {
        className,
        onclick: async () => {
          if (!accountState.loggedInUser) {
            const success = await accountState.openAccountModalPromise("signup");
            if (!success) return;
          }
          goToDashboard();
        },
      },
      "Get Started →"
    );
  },
};

const ClaimYourFreeMonthModalPage1: m.Component = {
  view() {
    const onClickStartTrial = () => {
      modalState.open({ modalView: () => m(ClaimYourFreeMonthModalPage2) });
    };
    return m(".modal-box.claim-trial-modal", [
      m(ModalX),
      m(".claim-trial-modal-left", [
        m("h1", "Try ", m(CuttlePro), " for free"),

        m("p", `Try all of our premium templates and features, free for 30 days!`),

        m(".pricing-feature", [
          m(Icon20, { icon: "check_bullet" }),
          m(
            ".pricing-feature-description",
            m.trust(`Make personalized <b>Ornaments</b>, <b>Cake Toppers</b>, and more in seconds`)
          ),
        ]),

        m(".pricing-feature", [
          m(Icon20, { icon: "check_bullet" }),
          m(
            ".pricing-feature-description",
            m.trust(
              `Customize your creations with <b>thousands of fonts</b> or upload your own fonts and images`
            )
          ),
        ]),

        m(".pricing-feature", [
          m(Icon20, { icon: "check_bullet" }),
          m(
            ".pricing-feature-description",
            m.trust(`<b>Unlimited downloads</b> for personal or commercial use`)
          ),
        ]),

        m("p", `Take advantage of this limited-time offer and start making more now!`),

        m("button.start-trial-button", { onclick: onClickStartTrial }, "Start my free trial"),
      ]),
      m(".claim-trial-modal-right"),
    ]);
  },
};

const ClaimYourFreeMonthModalPage2: m.ClosureComponent = () => {
  const info: UpgradeInfo = { paymentPeriod: "yearly" };
  return {
    view() {
      const dueDate = dayjs().add(30, "day");
      const reminderDate = dueDate.subtract(7, "day");
      return m(".modal-box.claim-trial-modal", [
        m(ModalX),
        m(".claim-trial-modal-left", [
          m("h1", "Try ", m(CuttlePro), " for free"),
          m(PricingFeature, "Free 30 day trial, cancel any time"),
          m(PricingFeature, "We'll remind you before your trial ends"),
          m(UpgradePlanSelector, { info }),
          m(".claim-trial-modal-due", [
            m("span.due.later", ["Due ", dueDate.format("MMMM D, YYYY")]),
            m("span.amount.later", info.paymentPeriod === "yearly" ? "US$180" : "US$19"),
            m("span.due", "Due today (30 days free)"),
            m("span.amount", "US$0"),
          ]),
          m(
            "button.start-trial-button",
            {
              onclick: async () => {
                logEvent("lcf032024 sent to stripe", { paymentPeriod: info.paymentPeriod });
                window.location.assign(
                  `https://api.cuttle.xyz/checkout/pro/${info.paymentPeriod}?trial=true`
                );
              },
            },
            "Continue"
          ),
        ]),
        m(".claim-trial-modal-right.page2", [
          m(".subscription-timeline", [
            m(".subscription-timeline-item", [
              m("span.subscription-timeline-icon", "🚀"),
              m("h3", "Today"),
              m("span.right", "Start your free 30 day Cuttle Pro trial. Cancel anytime."),
            ]),
            m(".subscription-timeline-item", [
              m("span.subscription-timeline-icon", "🔔"),
              m("h3", reminderDate.format("MMMM D")),
              m(
                "span.right",
                "We'll send you a reminder 7 days before your Cuttle Pro trial ends."
              ),
            ]),
            m(".subscription-timeline-item", [
              m("span.subscription-timeline-icon", "👑"),
              m("h3", dueDate.format("MMMM D")),
              m(
                "span.right",
                "Your subscription starts, unless you've cancelled during the trial."
              ),
            ]),
          ]),
          m(Testimonial, {
            image: "/images/testimonials/Heather-Simmonsen.jpg",
            quote:
              "I love how easy it is to create designs that adjust to different material thicknesses, kerf, and dimensions. Cuttle’s quickly become my favorite design tool.",
            name: "Heather Simmonsen (Chelsea Custom Creations)",
          }),
        ]),
      ]);
    },
  };
};

const AlreadyProModal: m.Component = {
  view() {
    return m(".modal-box.already-pro-modal", [
      m(ModalX),
      m(".already-pro-modal-content", [
        m("h1", "Looks like you already have Cuttle Pro!"),
        m(
          "button",
          { onclick: () => window.location.assign("/dashboard") },
          "Continue to My Projects"
        ),
      ]),
    ]);
  },
};

const UpgradePlanSelector: m.Component<{ info: UpgradeInfo }> = {
  view({ attrs: { info } }) {
    return m(".claim-trial-upgrade-plan-selector", [
      m(".claim-trial-upgrade-plans", [
        m(
          ".claim-trial-upgrade-plan",
          {
            className: classNames({ selected: info.paymentPeriod === "yearly" }),
            onclick: () => (info.paymentPeriod = "yearly"),
          },
          [
            m(PlanCheckbox, { checked: info.paymentPeriod === "yearly" }),
            [
              m("h3", ["Yearly", m("span.best-value", "Best Value - Save US$48")]),
              m("span.left", "US$180 ", m("span.dim", "(US$15/month)")),
            ],
          ]
        ),
        m(
          ".claim-trial-upgrade-plan",
          {
            className: classNames({ selected: info.paymentPeriod === "monthly" }),
            onclick: () => (info.paymentPeriod = "monthly"),
          },

          m(PlanCheckbox, { checked: info.paymentPeriod === "monthly" }),
          [m("h3", "Monthly"), m("span.left", "US$19")]
        ),
      ]),
    ]);
  },
};

const PlanCheckbox: m.Component<{ checked: boolean }> = {
  view({ attrs: { checked } }) {
    return m(".claim-trial-upgrade-plan-checkbox", [
      m(Icon20, { icon: checked ? "plan_checked" : "plan_unchecked" }),
    ]);
  },
};
